import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CalendarEventTimesChangedEvent } from "angular-calendar";
import cloneDeep from "lodash/cloneDeep";
import dayjs from "dayjs";
import { Subject, Subscription } from "rxjs";
import { ICalendarPostEvent } from "../../../shared/interfaces/ICalendarPostEvent";
import { CampaignPartnerStatus } from "../../../shared/enums/campaign.enums";
import {
  PostPartnerStatus,
  PostPlatform,
} from "../../../shared/enums/campaignPost.enums";
import { Partner } from "../../../shared/models/partner";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerCampaignCalendar } from "../../../shared/models/partnerCampaignCalendar";
import { PartnerCampaignDetails } from "../../../shared/models/partnerCampaignDetail";
import { PartnerFacebookPostLog } from "../../../shared/models/partnerFacebookPostLog";
import { PartnerGoogleAdPostLog } from "../../../shared/models/partnerGoogleAdPostLog";
import { PartnerLinkedInPostLog } from "../../../shared/models/partnerLinkedInPostLog";
import { PostLog } from "../../../shared/models/postLog";
import { PartnerCampaignPostLogService } from "../../shared/services/partner-campaign-post-log.service";
import { PartnerCampaignService } from "../../../shared/services/api/partner-campaign.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { UpdateFacebookPostLogData } from "../../../shared/services/parameters/update-facebook-post-log-data";
import { UpdateGoogleAdPostLogData } from "../../../shared/services/parameters/update-google-ad-post-log-data";
import { UpdateLinkedinPostLogData } from "../../../shared/services/parameters/update-linkedin-post-log-data";
import { RequestErrorService } from "../../../shared/services/request-error.service";
import { PartnerCampaignBudgetConfirmDialogData } from "../partner-campaign-budget-confirm-dialog/partner-campaign-budget-confirm-dialog-data";
import { PartnerCampaignBudgetPostConfirmDialogData } from "../partner-campaign-budget-post-confirm-dialog/partner-campaign-budget-post-confirm-dialog-data";
import { PartnerCampaignBudgetPostConfirmDialogComponent } from "../partner-campaign-budget-post-confirm-dialog/partner-campaign-budget-post-confirm-dialog.component";
import {
  PartnerCampaignFacebookPostEditDialogComponent,
  PartnerCampaignFacebookPostEditDialogData,
} from "../partner-campaign-facebook-post-edit-dialog/partner-campaign-facebook-post-edit-dialog.component";
import {
  PartnerCampaignGoogleAdEditDialogComponent,
  PartnerCampaignGoogleAdEditDialogData,
} from "../partner-campaign-google-ad-edit-dialog/partner-campaign-google-ad-edit-dialog.component";
import { PartnerCampaignLinkedInPostEditDialogData } from "../partner-campaign-linkedin-post-edit-dialog/partner-campaign-linkedin-post-edit-dialog-data";
import { PartnerCampaignLinkedInPostEditDialogComponent } from "../partner-campaign-linkedin-post-edit-dialog/partner-campaign-linkedin-post-edit-dialog.component";
import { CountPendingPublicationIntentsInteractor } from "../publication-intent/domain/interactors/count-pending-publication-intents.interactor";
import { CalendarSelectCampaignWarningDialogComponent } from "./select-campaign-warning-dialog/select-campaign-warning-dialog.component";
import { DateService } from "../../../shared/services/date.service";
import { safeLocalStorage } from "../../../shared/utils/safe-storage";
import { getLanguageSignal } from "../../../shared/services/language.service";
import { PartnerDialogService } from "../../partner-dialog.service";
import { DialogService } from "../../../shared/services/dialog.service";
import { GuideTooltipAction } from "../../../shared/components/guide-tooltip/guide-tooltip.component";
import { PartnerService } from "../../../shared/services/api/partner.service";

export enum TooltipType {
  DragAndDrop,
  TransparentPost,
}

@Component({
  selector: "app-partner-campaign-content-calendar",
  templateUrl: "./partner-campaign-content-calendar.component.html",
  styleUrl: "./partner-campaign-content-calendar.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class PartnerCampaignContentCalendarComponent
  implements OnInit, OnDestroy
{
  // TODO: Change `app-guide-tooltip` to accept this as Input() instead of using "parent" trickery. Then, change visibility to `protected`.
  public readonly tutorialStepChanged$ = new EventEmitter<string>();

  protected readonly language = getLanguageSignal();
  protected readonly PostPartnerStatus = PostPartnerStatus;
  protected readonly TooltipType = TooltipType;
  protected readonly ViewType = "month";
  protected activeTooltipStep = 1;
  protected areTherePendingCalendarPosts = false;
  protected areTherePendingPublicationIntents = false;
  protected campaign: PartnerCampaign;
  protected eventHovered?: ICalendarPostEvent;
  protected events: ICalendarPostEvent[] = [];
  protected loading = true;
  protected refresh = new Subject<void>();
  protected viewDate?: Date;

  private readonly subscriptions = new Subscription();
  private campaignEndDate: Date = new Date();
  private campaignStartDate: Date = new Date();
  private daysFromStartToDrag?: number;
  private fakeEvent?: ICalendarPostEvent;
  private nowDate = new Date();
  private partner: Partner;
  private refreshInterval?: number;
  private tooltipStorageKey!: string;

  constructor(
    private readonly countPendingPublicationIntents: CountPendingPublicationIntentsInteractor,
    private readonly dateService: DateService,
    private readonly dialog: MatDialog,
    private readonly dialogService: DialogService,
    private readonly notificationService: NotificationService,
    private readonly partnerCampaignPostLogService: PartnerCampaignPostLogService,
    private readonly partnerCampaignService: PartnerCampaignService,
    private readonly partnerDialogService: PartnerDialogService,
    private readonly partnerService: PartnerService,
    private readonly requestErrorService: RequestErrorService,
  ) {
    this.partner = this.partnerService.currentPartner;
    this.campaign = this.partnerCampaignService.currentCampaign!;
  }

  public ngOnInit(): void {
    this.loading = true;

    window.scrollTo(0, 0);

    this.initCampaignData(this.campaign);
    this.subscriptions.add(
      this.partnerCampaignService.partnerCampaignUpdated.subscribe(
        (campaign: PartnerCampaign) => {
          this.initCampaignData(campaign);
        },
      ),
    );

    this.subscriptions.add(
      this.partnerCampaignService.partnerCampaignBudgetEditedEvent.subscribe(
        () => {
          this.reloadCampaign();
        },
      ),
    );

    this.tooltipStorageKey = "calendar-tutorial-step-" + this.partner.id;
  }

  public ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
    clearInterval(this.refreshInterval);
  }

  private reloadCampaign(): void {
    this.partnerCampaignService
      .getCurrentPartnerCampaign(true)
      .subscribe((campaign) => {
        if (campaign) {
          this.initCampaignData(campaign);
        }
      });
  }

  private initCampaignData(campaign: PartnerCampaign): void {
    this.campaign = campaign;
    this.campaignStartDate = dayjs(campaign.startDate).startOf("day").toDate();
    this.campaignEndDate = dayjs(campaign.endDate).endOf("day").toDate();

    if (!this.viewDate) {
      this.viewDate = campaign.isOngoing ? new Date() : this.campaignStartDate;
    }

    this.showSelectCampaignPopUpIfNeeded();
    this.initEvents();
    this.loading = false;
  }

  protected showApprovedCalendarTopBanner(): boolean {
    return this.campaign && this.campaign?.hasPartnerApprovedContentCalendar;
  }

  private async initEvents(): Promise<void> {
    this.events = [];
    let postTooltipShown = false;
    if (this.campaign && this.campaign.posts) {
      this.campaign.posts.forEach((post) => {
        if (this.doesPostShowInThisMonth(post) && postTooltipShown === false) {
          postTooltipShown = true;
          // This post has a tooltip
          this.processPost(post, TooltipType.DragAndDrop);
        } else {
          this.processPost(post, undefined);
        }
      });
    }
    if (
      this.campaign &&
      this.campaign.currentCalendar &&
      this.campaign.currentCalendar.calendarPosts.length > 0
    ) {
      let calendarPostTooltipShown = false;
      let calendarTransparentPostTooltipShown = false;
      this.areTherePendingCalendarPosts = false;
      this.campaign.currentCalendar.calendarPosts.forEach((post: PostLog) => {
        if (post.partnerStatus === this.PostPartnerStatus.Pending) {
          this.areTherePendingCalendarPosts = true;
        }
        // This is for showing tooltips on calendar events
        // We need to show one on PARTNER_PENDING and the other on
        // PARTNER_PENDING or PARTNER_APPROVED
        if (
          this.doesPostShowInThisMonth(post) &&
          calendarTransparentPostTooltipShown === false
        ) {
          calendarTransparentPostTooltipShown = true;
          this.processPost(post, TooltipType.TransparentPost);
        } else if (
          this.doesPostShowInThisMonth(post) &&
          calendarPostTooltipShown === false &&
          postTooltipShown === false
        ) {
          calendarPostTooltipShown = true;
          // This post has a tooltip
          this.processPost(post, TooltipType.DragAndDrop);
        } else {
          this.processPost(post);
        }
      });
    }

    if (this.campaign) {
      const tooltipStep = safeLocalStorage.getItem(this.tooltipStorageKey);
      if (
        this.campaign.hasPartnerApprovedContentCalendar ||
        (tooltipStep && parseInt(tooltipStep, 10) >= 3)
      ) {
        this.activeTooltipStep = 0; // 0 Means it will not show
      }

      const countPending = await this.countPendingPublicationIntents.execute(
        this.campaign.id,
        this.partner.id,
      );
      this.areTherePendingPublicationIntents = countPending > 0;
    }

    this.refreshInterval = window.setInterval(() => {
      this.fetchLoadingPosts();
    }, 5000);

    this.setEventsPositions();
  }

  private processPost(post: PostLog, showTooltipType?: TooltipType): void {
    // Only posts with the following criteria can be edited:
    // - Has been processed (published or scheduled) into FB's system
    // Only posts with the following criteria can be dragged:
    // - Is not editable
    // - Is not a Calendar Post
    // - Is not already published
    // - Is not in the past
    const draggable = this.isPostDraggable(post);

    let title = "";
    if (post.isFacebookPost) {
      const facebookPost = post as PartnerFacebookPostLog;
      title = facebookPost.text;
    } else if (post.isGoogleAd) {
      const googlePost = post as PartnerGoogleAdPostLog;
      title = googlePost.descriptionOne;
    } else if (post.isLinkedInPost) {
      const linkedInPost = post as PartnerLinkedInPostLog;
      title = linkedInPost.text;
    }

    this.events = this.events.filter((event) => event.post!.id !== post.id);

    const postDate = this.getPostStartDate(post);

    const newPost: ICalendarPostEvent = {
      title: title,
      start: postDate,
      post: post,
      draggable: draggable,
      position: undefined,
      blocked: false, // Flag used to refresh this event's post unless it's blocked by the frontend
      showTooltipType: showTooltipType, // Show tooltip only on first event. SUGGESTED_POST, PUBLISHED_POST
    };

    if (
      (post.isGoogleAd || (post.isFacebookPost && post.hasAd)) &&
      post.ad &&
      post.ad.endDate
    ) {
      newPost.end = post.ad.endDate;
    }

    this.events.push(newPost);
  }

  private isPostDraggable(post: PostLog): boolean {
    if (post instanceof PartnerFacebookPostLog) {
      return !post.isPublishedByInstagram && !post.publishedByPlatform;
    }
    return !post.publishedByPlatform;
  }

  protected isEventLoading(event: ICalendarPostEvent): boolean {
    return event && (!event.post?.calendarPost?.id || !!event.loading);
  }

  private getPostStartDate(post: PostLog): Date {
    if ((post.isGoogleAd || post.isFacebookPost) && post.hasAd) {
      return post.ad!.startDate;
    }

    // console.warn(post.scheduledPublishDate);

    return post.scheduledPublishDate;
  }

  private fetchLoadingPosts(): void {
    this.events.forEach((event) => {
      if (!event.blocked && this.isEventLoading(event)) {
        this.partnerCampaignPostLogService
          .getCampaignPostLog(event.post!.id)
          .subscribe({
            next: (result) => {
              event.draggable = this.isPostDraggable(result);
              event.post = result;
              this.refresh.next();
            },
            error: () => {
              clearInterval(this.refreshInterval);
            },
          });
      }
    });
  }

  protected eventTimesChanged({
    event,
    newStart,
  }: CalendarEventTimesChangedEvent): void {
    const eventChanged = event as ICalendarPostEvent;
    // Save old value in case the transaction fails
    const oldStart = eventChanged.start!;
    const oldEnd = eventChanged.end!;

    // Update the variable in memory and the calendar's UI to show immediate feedback to user
    eventChanged.loading = true;
    eventChanged.blocked = true;

    eventChanged.start = new Date(newStart);
    eventChanged.start.setDate(
      eventChanged.start.getDate() - this.daysFromStartToDrag!,
    );
    if (eventChanged.end) {
      eventChanged.end = new Date(eventChanged.start);
      eventChanged.end.setDate(
        eventChanged.end.getDate() + this.getDaysBetween(oldStart, oldEnd),
      );
    }

    this.refresh.next();

    if (this.isDayOutsideRange(newStart)) {
      this.notificationService.error(
        "partner.campaign.contentCalendar.errorUpdatingPostOutOfTimeFrame",
        "shared.changesCouldNotBeSaved",
      );
      this.rollBackEvent(eventChanged, oldStart, oldEnd);
    } else if (newStart < new Date()) {
      this.notificationService.error(
        "partner.campaign.contentCalendar.errorUpdatingPostDatePast",
        "shared.changesCouldNotBeSaved",
      );
      this.rollBackEvent(eventChanged, oldStart, oldEnd);
    } else {
      const oldDate = dayjs(eventChanged.post!.scheduledPublishDate);
      let newDate = dayjs(eventChanged.start);

      if (
        this.getComparableDateTimeString(oldStart) ===
        this.getComparableDateTimeString(event.start)
      ) {
        eventChanged.loading = false;
        eventChanged.blocked = false;
        return; // If is dragged on the same day we do not update it
      }

      newDate = newDate.minute(oldDate.minute());
      newDate = newDate.hour(oldDate.hour());

      const postLog = eventChanged.post!;
      postLog.scheduledPublishDate = newDate.toDate();

      this.partnerCampaignPostLogService
        .updateCampaignPost(
          eventChanged.post!.id,
          this.getUpdatePostLogData(postLog!),
        )
        .subscribe({
          next: (result) => {
            eventChanged.post = result;
            // Update post's date value
            eventChanged.post.scheduledPublishDate = newDate.toDate();
            eventChanged.loading = false;
            eventChanged.blocked = false;
            this.resetEventPosition();
            this.notificationService.success(
              "partner.campaign.contentCalendar.postUpdatedSubtitle",
              "partner.campaign.contentCalendar.postUpdatedTitle",
            );
          },
          error: () => {
            // Undo the change, refresh the UI and show error message to user
            eventChanged.start = oldStart;
            eventChanged.loading = false;
            eventChanged.blocked = false;
            this.refresh.next();
            this.notificationService.error(
              "shared.changesCouldNotBeSaved",
              "partner.campaign.contentCalendar.errorUpdatingPost",
            );
          },
        });
    }
  }

  private getUpdatePostLogData(
    post: PostLog,
  ):
    | UpdateFacebookPostLogData
    | UpdateGoogleAdPostLogData
    | UpdateLinkedinPostLogData {
    switch (post.platform) {
      case PostPlatform.Facebook:
        return new UpdateFacebookPostLogData(post as PartnerFacebookPostLog);
      case PostPlatform.Google:
        return new UpdateGoogleAdPostLogData(post as PartnerGoogleAdPostLog);
      case PostPlatform.LinkedIn:
        return new UpdateLinkedinPostLogData(post as PartnerLinkedInPostLog);
    }
  }

  private rollBackEvent(
    event: ICalendarPostEvent,
    oldStart: Date,
    oldEnd: Date,
  ): void {
    // Undo the change, refresh the UI and show error message to user
    event.start = oldStart;
    event.end = oldEnd;
    event.loading = false;
    this.refresh.next();
  }

  protected async onEventClick(
    mouseEvent: MouseEvent,
    event: ICalendarPostEvent,
  ): Promise<void> {
    if (this.isDragInProgress()) {
      return;
    }

    if (mouseEvent.stopPropagation) {
      mouseEvent.stopPropagation();
    }

    const eventPostLog = event.post as PostLog;
    if (eventPostLog.isFacebookPost) {
      const post = eventPostLog as PartnerFacebookPostLog;
      if (!post.publishedByPlatform && !post.isPublishedByInstagram) {
        const dialogRef = this.dialog.open(
          PartnerCampaignFacebookPostEditDialogComponent,
          {
            width: "800px",
            data: {
              campaign: this.campaign,
              partner: this.partner,
              postLog: post,
            } as PartnerCampaignFacebookPostEditDialogData,
            disableClose: true,
          },
        );
        this.setPostEditDialogEventSubscribers(dialogRef, event);
      } else {
        await this.dialogService.showPostPreview({
          post: post,
          partner: this.partner,
        });
      }
    } else if (eventPostLog.isGoogleAd) {
      const post = eventPostLog as PartnerGoogleAdPostLog;
      if (!post.publishedByPlatform) {
        const dialogRef = this.dialog.open(
          PartnerCampaignGoogleAdEditDialogComponent,
          {
            width: "750px",
            data: {
              campaign: this.campaign,
              partner: this.partner,
              postLog: post,
            } as PartnerCampaignGoogleAdEditDialogData,
            disableClose: true,
          },
        );
        this.setPostEditDialogEventSubscribers(dialogRef, event);
      } else {
        await this.dialogService.showPostPreview({ post: post });
      }
    } else if (eventPostLog.isLinkedInPost) {
      const post = eventPostLog as PartnerLinkedInPostLog;
      // If is a postLog published or if it's a calendar suggested post.
      if (!post.publishedByPlatform) {
        const dialogRef = this.dialog.open(
          PartnerCampaignLinkedInPostEditDialogComponent,
          {
            width: "800px",
            data: new PartnerCampaignLinkedInPostEditDialogData(
              this.campaign,
              this.partner,
              post,
            ),
            disableClose: true,
          },
        );
        this.setPostEditDialogEventSubscribers(dialogRef, event);
      } else {
        await this.dialogService.showPostPreview({ post: post });
      }
    } else {
      throw new Error("Invalid post platform type");
    }
  }

  private setPostEditDialogEventSubscribers(
    dialogRef:
      | MatDialogRef<PartnerCampaignFacebookPostEditDialogComponent>
      | MatDialogRef<PartnerCampaignGoogleAdEditDialogComponent>
      | MatDialogRef<PartnerCampaignLinkedInPostEditDialogComponent>,
    event: ICalendarPostEvent,
  ) {
    this.subscriptions.add(
      dialogRef.componentInstance.campaignPostLogUpdatedEvent.subscribe(
        (changedPostLog) => {
          event.start = changedPostLog.scheduledPublishDate;
          if (
            changedPostLog.isGoogleAd ||
            (changedPostLog.isFacebookPost &&
              changedPostLog.hasAd &&
              changedPostLog.ad &&
              changedPostLog.ad.endDate)
          ) {
            event.end = changedPostLog.ad!.endDate;
          } else {
            event.end = undefined;
          }
          event.post = changedPostLog;
          this.refresh.next();
          this.reloadCampaign();
        },
      ),
    );

    this.subscriptions.add(
      dialogRef.componentInstance.postRemoved.subscribe(() => {
        dialogRef.close();
        this.events.splice(this.events.indexOf(event), 1);
        this.refresh.next();
        this.reloadCampaign();
      }),
    );
  }

  protected isCalendarOnThisMonth(): boolean {
    const now = new Date();

    return (
      now.getMonth() === this.viewDate!.getMonth() &&
      now.getFullYear() === this.viewDate!.getFullYear()
    );
  }

  private async openEditBudgetDialog(
    budget?: number,
    adDuration?: number,
    numberOfAds?: number,
  ): Promise<void> {
    const confirmBudgetDialogRef =
      await this.partnerDialogService.showPartnerCampaignBudgetEdit({
        campaign: this.campaign,
        budget: budget,
        adDuration: adDuration,
        numberOfAds: numberOfAds,
      });

    if (confirmBudgetDialogRef) {
      this.subscriptions.add(
        confirmBudgetDialogRef.componentInstance.backEvent.subscribe(
          (newBudget: PartnerCampaignBudgetConfirmDialogData) => {
            this.openEditBudgetDialog(
              newBudget.budget,
              newBudget.adDuration,
              newBudget.numberOfAds,
            );
          },
        ),
      );
    }

    this.subscriptions.add(
      this.partnerCampaignService.partnerCampaignBudgetEditedEvent.subscribe(
        () => {
          this.reloadCampaign();
          this.startCalendarSetup();
        },
      ),
    );
  }

  private openConfirmBudgetDialog(): void {
    const confirmBudgetDialog = this.dialog.open(
      PartnerCampaignBudgetPostConfirmDialogComponent,
      {
        width: "784px",
        data: new PartnerCampaignBudgetPostConfirmDialogData(this.campaign),
        disableClose: true,
      },
    );

    this.subscriptions.add(
      confirmBudgetDialog.componentInstance.confirmButtonClickEvent.subscribe(
        () => {
          confirmBudgetDialog.close();
          this.startCalendarSetup();
        },
      ),
    );

    this.subscriptions.add(
      confirmBudgetDialog.componentInstance.editBudgetClickEvent.subscribe(
        () => {
          confirmBudgetDialog.close();
          this.openEditBudgetDialog();
        },
      ),
    );
  }

  protected async startCalendarSetup(): Promise<void> {
    if (this.campaign.isPartnerPendingToDefineACustomCampaignBudget) {
      this.openConfirmBudgetDialog();
      return;
    }

    const startSetup = await this.partnerDialogService.showStartSetupCalendar({
      campaign: this.campaign,
    });

    if (!startSetup) {
      return;
    }

    const isPartnerConnected =
      await this.partnerDialogService.showThirdPartyConnectionsCheck({
        context: "calendar",
      });

    if (!isPartnerConnected) {
      return;
    }

    const acceptedPosts = await this.partnerDialogService.showPostAcceptance({
      campaign: this.campaign,
      campaignDetails: this.campaign.currentDetails!,
      partner: this.partner,
    });

    if (acceptedPosts) {
      this.finishAcceptance(acceptedPosts);
    }
  }

  private finishAcceptance(posts: PostLog[]): void {
    this.loading = true;

    const finalPostsData = posts.map((post) => ({
      id: post.id,
      scheduledPublishDate: dayjs(post.scheduledPublishDate).unix(),
    }));

    const address = this.campaign.partnerDefaultAddress;
    const adsData = {
      latitude: address.latitude,
      longitude: address.longitude,
      posts: finalPostsData,
    };

    this.partnerCampaignService
      .acceptCalendar(
        this.campaign.id,
        this.campaign.currentDetails!.id,
        this.campaign.currentCalendar!.id,
        adsData,
      )
      .subscribe({
        next: (response) => {
          setTimeout(() => {
            this.loading = false;
            this.partnerDialogService.showCampaignPostsCheck({
              postsWithError:
                response.errors?.length > 0 ? response.errors : [],
            });
          }, 2000);
          this.reloadCampaign();
        },
        error: (err) => {
          setTimeout(() => {
            this.loading = false;
            if (err.error?.key === "FB_PARTNER_AUTH_ERROR") {
              this.requestErrorService.partnerNotConnectedError();
            } else if (err.error?.key === "PARTNER_ERROR_INVALID_ADDRESS") {
              this.notificationService.error(
                "partner.campaign.contentCalendar.setupDialog.invalidAddressWarning",
              );
            }
          }, 1000);
        },
      });
  }

  protected isDayOutsideRange(date: Date): boolean {
    return this.dateService.isDateOutsideRange(date, {
      startDate: this.campaignStartDate,
      endDate: this.campaignEndDate,
    });
  }

  protected toolTipAction(event: GuideTooltipAction): void {
    // Handle next or close events
    if (event === "next") {
      this.activeTooltipStep++;
    }
    if (event === "skip") {
      this.activeTooltipStep = 5;
    }
    safeLocalStorage.setItem(
      this.tooltipStorageKey,
      this.activeTooltipStep.toString(),
    );
    this.tutorialStepChanged$.emit(this.activeTooltipStep.toString());
  }

  private doesPostShowInThisMonth(post: PostLog): boolean {
    const postDate = this.getPostStartDate(post);

    if (!postDate) {
      return false;
    }

    return (
      dayjs(this.viewDate).month() === dayjs(postDate).month() &&
      dayjs(this.viewDate).year() === dayjs(postDate).year()
    );
  }

  protected changeCalendar(
    details: PartnerCampaignDetails,
    calendar: PartnerCampaignCalendar,
  ): void {
    // This is to send the correct CampaignDetails to CalendarSetupStartDialogComponent
    this.campaign.changeLanguage(details.language.locale);
    this.campaign.changeCampaignCalendar(calendar);
    this.partnerCampaignService.partnerCampaignUpdated.emit(this.campaign);
  }

  protected shouldShowCalendarSelector(): boolean {
    if (!this.campaign || this.campaign?.hasPartnerApprovedContentCalendar) {
      return false;
    }

    if (
      this.campaign.campaignPartnerStatus !== CampaignPartnerStatus.Validated &&
      this.campaign.campaignPartnerStatus !== CampaignPartnerStatus.Engaged
    ) {
      return false;
    }

    let totalCalendars = 0;
    if (this.campaign) {
      for (const details of this.campaign.details) {
        if (details.calendars) {
          totalCalendars += details.calendars.length;
        }
      }
    }
    return totalCalendars > 1;
  }

  private showSelectCampaignPopUpIfNeeded(): void {
    if (!this.partner) {
      return;
    }
    const cacheKey =
      "campaign-pop-up-" + this.partner.id + "-" + this.campaign.id;
    if (
      !safeLocalStorage.getItem(cacheKey) &&
      this.shouldShowCalendarSelector()
    ) {
      setTimeout(() => {
        this.dialog.open(CalendarSelectCampaignWarningDialogComponent, {
          width: "536px",
        });
      });
      safeLocalStorage.setItem(cacheKey, JSON.stringify(true));
    }
  }

  protected onEventDragStart(event: ICalendarPostEvent, day: Date): void {
    this.daysFromStartToDrag = this.getDaysBetween(event.start, day);
    this.fakeEvent = cloneDeep(event);
    this.fakeEvent.isFake = true;
    this.events.push(this.fakeEvent);
    event.isBeingDragged = true;
  }

  protected onMouseUp(): void {
    if (this.isDragInProgress()) {
      this.fakeEvent = undefined;
      this.events.pop();
      this.events.forEach(
        (e: ICalendarPostEvent) => (e.isBeingDragged = false),
      );
      this.resetEventPosition();
    }
  }

  protected mouseOverDay(date: Date): void {
    if (this.fakeEvent) {
      // dragging
      for (const event of this.events) {
        if (event.isFake) {
          this.setFakeDayWhileDrag(event, date);
        }
      }
      this.resetEventPosition();
    }
  }

  private setFakeDayWhileDrag(event: ICalendarPostEvent, date: Date): void {
    event.dragStartDate = new Date(date);
    event.dragStartDate.setDate(date.getDate() - this.daysFromStartToDrag!);
    const eventDays = event.end
      ? this.getDaysBetween(event.start, event.end)
      : 0;
    event.dragEndDate = new Date(event.dragStartDate);
    event.dragEndDate.setDate(event.dragEndDate.getDate() + eventDays);
  }

  // SET EVENTS POSITIONS

  protected getFilledEventArray(date: Date): ICalendarPostEvent[] {
    const filledArray: ICalendarPostEvent[] = [];
    const events = this.getDayEvents(date);

    let maxPosition = -1;
    for (const event of events) {
      if (event.position! > maxPosition) {
        maxPosition = event.position!;
      }
    }

    for (
      let currentPosition = 0;
      currentPosition <= maxPosition;
      currentPosition++
    ) {
      const currentEvent = events.find(
        (event) => event.position === currentPosition,
      );
      if (currentEvent) {
        filledArray.push(currentEvent);
      } else {
        filledArray.push({} as ICalendarPostEvent);
      }
    }

    return filledArray;
  }

  private resetEventPosition(): void {
    for (const event of this.events) {
      event.position = undefined;
    }
    this.setEventsPositions();
  }

  private setEventsPositions(): void {
    const [firstDay, lastDay] = this.findEventsRange();
    const loopLastDay = new Date(lastDay!);

    loopLastDay.setDate(loopLastDay.getDate() + 1);

    for (
      let day = new Date(firstDay!);
      day <= loopLastDay;
      day.setDate(day.getDate() + 1)
    ) {
      const dayEvents = this.getDayEvents(day);
      for (const event of dayEvents) {
        if (event.isBeingDragged) {
          event.position = dayEvents.length + 1;
        }
        if (event.position === undefined || event.position === null) {
          event.position = this.setFirstFreePosition(dayEvents);
        }
      }
    }
  }

  private setFirstFreePosition(dayEvents: ICalendarPostEvent[]): number {
    let currentPosition = 0;
    let found = true;
    while (found) {
      found = false;
      for (const e of dayEvents) {
        if (e.position === currentPosition) {
          found = true;
          break;
        }
      }
      if (found) {
        currentPosition++;
      }
    }
    return currentPosition;
  }

  private getDayEvents(currentDay: Date): ICalendarPostEvent[] {
    return this.events.filter((event) => {
      const eventStart = this.startDay(event);
      const eventEnd = this.endDay(event);

      if (!eventEnd) {
        return (
          this.getComparableDateTimeString(eventStart) ===
          this.getComparableDateTimeString(currentDay)
        );
      }

      // We only keep the event from the dragged day
      const dayDragged = new Date(event.start);
      dayDragged.setDate(dayDragged.getDate() + this.daysFromStartToDrag!);
      if (
        event.isBeingDragged &&
        !event.isFake &&
        this.getComparableDateTimeString(currentDay) !==
          this.getComparableDateTimeString(dayDragged)
      ) {
        return false;
      }

      for (
        let day = new Date(eventStart);
        this.getComparableDateString(day) <=
        this.getComparableDateString(eventEnd);
        day.setDate(day.getDate() + 1)
      ) {
        if (
          this.getComparableDateTimeString(day) ===
          this.getComparableDateTimeString(currentDay)
        ) {
          return true;
        }
      }
      return false;
    });
  }

  private findEventsRange(): [Date | undefined, Date | undefined] {
    let minDate: Date | undefined;
    let maxDate: Date | undefined;

    for (const event of this.events) {
      const eventStart = this.startDay(event);
      const eventEnd = this.endDay(event);

      if (!minDate) {
        minDate = eventStart;
      } else if (minDate > eventStart) {
        minDate = eventStart;
      }

      if (!maxDate) {
        maxDate = eventEnd ? eventEnd : eventStart;
      } else if (eventEnd) {
        if (eventEnd > maxDate) {
          maxDate = eventEnd;
        }
      } else if (eventStart > maxDate) {
        maxDate = eventStart;
      }
    }

    return [minDate, maxDate];
  }

  private getComparableDateString(date: Date): string {
    return dayjs(date).format("YYYYMMDD");
  }

  protected isEmptyObject(object: Record<string | number, unknown>): boolean {
    return Object.keys(object).length === 0;
  }

  protected isFirstDay(day: Date, event: ICalendarPostEvent): boolean {
    return this.endDay(event)
      ? this.getComparableDateTimeString(day) ===
          this.getComparableDateTimeString(this.startDay(event))
      : false;
  }

  protected isCenterDay(day: Date, event: ICalendarPostEvent): boolean {
    return (
      !this.isOneDayEvent(event) &&
      !this.isFirstDay(day, event) &&
      !this.isLastDay(day, event)
    );
  }

  protected isLastDay(day: Date, event: ICalendarPostEvent): boolean {
    return this.endDay(event)
      ? this.getComparableDateTimeString(day) ===
          this.getComparableDateTimeString(this.endDay(event))
      : false;
  }

  protected isOneDayEvent(event: ICalendarPostEvent) {
    return (
      !this.endDay(event) ||
      this.getComparableDateTimeString(this.startDay(event)) ===
        this.getComparableDateTimeString(this.endDay(event))
    );
  }

  private getDaysBetween(date1: Date, date2: Date) {
    return Math.ceil((date2.valueOf() - date1.valueOf()) / (1000 * 3600 * 24));
  }

  private startDay(event: ICalendarPostEvent): Date {
    return event.dragStartDate ? event.dragStartDate : event.start;
  }

  private endDay(event: ICalendarPostEvent): Date | undefined {
    return event.dragEndDate ? event.dragEndDate : event.end;
  }

  private isDragInProgress(): boolean {
    return !!this.fakeEvent;
  }

  protected isMultiDayPost(post: PostLog): boolean {
    if (!post) {
      return false;
    }
    if (post.isGoogleAd) {
      return true;
    }
    return post.isFacebookPost && post.hasAd;
  }

  protected onEventHover(
    event: MouseEvent,
    postEvent: ICalendarPostEvent,
  ): void {
    this.eventHovered = postEvent;

    // element related values (scaled to 0.75)
    const tooltipHeight = 401 + 32;
    const tooltipWidth = 350 + 32;

    // event related values
    const eventWidth = (event.target as HTMLElement).offsetWidth;
    const eventHeight = (event.target as HTMLElement).offsetHeight;

    const topSpace = event.clientY;
    const bottomSpace = window.innerHeight - event.clientY;
    const rightSpace = window.innerWidth - event.clientX;
    const leftSpace = event.clientX;

    // screen related values
    const requiredVSpace = tooltipHeight + eventHeight;
    const requiredHSpace = tooltipWidth + eventWidth;
    const requiredVSpaceHalf = tooltipHeight / 2 + eventHeight / 2;
    const requiredHSpaceLeft = tooltipWidth / 2 - eventWidth / 2;
    const requiredHSpaceRight = tooltipWidth / 2 + eventWidth / 2;

    const hasSpaceLeft =
      leftSpace > requiredHSpace + 50 &&
      topSpace > requiredVSpaceHalf &&
      bottomSpace > requiredVSpaceHalf;
    const hasSpaceRight =
      rightSpace > requiredHSpace &&
      topSpace > requiredVSpaceHalf &&
      bottomSpace > requiredVSpaceHalf;
    const hasSpaceTop =
      topSpace > requiredVSpace &&
      leftSpace > requiredHSpaceLeft &&
      rightSpace > requiredHSpaceRight;
    const hasSpaceBottom =
      bottomSpace > requiredVSpace &&
      leftSpace > requiredHSpaceLeft &&
      rightSpace > requiredHSpaceRight;

    // can be shown on the left?
    if (hasSpaceLeft) {
      postEvent.tooltipPlacement = "left";
    } else if (hasSpaceRight) {
      postEvent.tooltipPlacement = "right";
    } else if (hasSpaceTop) {
      postEvent.tooltipPlacement = "top";
    } else if (hasSpaceBottom) {
      postEvent.tooltipPlacement = "bottom";
    } else {
      postEvent.tooltipPlacement = "left";
    }
  }

  protected onEventLeave(): void {
    this.eventHovered = undefined;
  }

  private getComparableDateTimeString(date?: Date): string {
    if (!date) {
      return "";
    }

    const dayjsDate = dayjs(date);

    return (
      dayjsDate.year() +
      "" +
      ("0" + (dayjsDate.month() + 1)).slice(-2) +
      "" +
      ("0" + dayjsDate.date()).slice(-2)
    );
  }

  protected isDarkPost(postLog: PostLog): boolean {
    if (postLog instanceof PartnerFacebookPostLog) {
      return postLog.hasAd && postLog.isDarkPost;
    }
    return false;
  }

  protected showFacebookIcon(post: PostLog): boolean {
    return post instanceof PartnerFacebookPostLog && post.postToFacebook;
  }

  protected showInstagramIcon(post: PostLog): boolean {
    if (post instanceof PartnerFacebookPostLog) {
      if (post.scheduledPublishDate > this.nowDate && post.postToInstagram) {
        return true;
      }

      if (
        post.scheduledPublishDate < this.nowDate &&
        post.isPublishedByInstagram
      ) {
        return true;
      }
    }
    return false;
  }
}
