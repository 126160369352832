<div class="loader-wrapper">
  <app-loader
    [text]="'shared.loadingCampaign' | translate"
    [ngClass]="{ visible: loading }"
  >
  </app-loader>
  <div *ngIf="campaign" class="calendar-view-wrapper">
    <div
      *ngIf="!campaign.hasPartnerApprovedContentCalendar"
      class="calendar-explanation-banner"
    >
      <div>
        <img
          class="calendar-explanation-banner-posts-image"
          src="/assets/images/transparent-to-visible.svg"
        />
      </div>
      <div class="texts">
        <div class="title">
          {{ "shared.transparent" | translate }}
          {{
            "partner.campaign.contentCalendar.infoNotPublishedTitle" | translate
          }}
        </div>
        <div
          class="subtitle"
          [innerHTML]="
            'partner.campaign.contentCalendar.infoNotPublishedSubtitle'
              | translate
          "
        ></div>
      </div>
    </div>
    <div
      *ngIf="showApprovedCalendarTopBanner()"
      class="calendar-explanation-banner"
    >
      <div>
        <img
          class="calendar-explanation-banner-girl-image"
          src="/assets/images/girl-info-black.svg"
        />
      </div>
      <div class="texts">
        <div class="title">
          {{
            "partner.campaign.contentCalendar.infoPublishedTitle" | translate
          }}
        </div>
        <div
          class="subtitle"
          [innerHTML]="
            'partner.campaign.contentCalendar.infoPublishedSubtitle' | translate
          "
        ></div>
      </div>
    </div>
    <div class="calendar-header">
      <div class="left-content">
        <div
          class="button-transparent today"
          [class.active]="isCalendarOnThisMonth()"
          mwlCalendarToday
          [(viewDate)]="viewDate"
        >
          <span>{{
            "partner.campaign.contentCalendar.calendarToday" | translate
          }}</span>
        </div>
        <div class="calendar-navigation">
          <div
            class="button-navigation"
            mwlCalendarPreviousView
            [view]="ViewType"
            [(viewDate)]="viewDate"
          >
            <span>
              <mat-icon
                class="mat-icon material-icons"
                role="img"
                aria-hidden="true"
                >arrow_back_ios</mat-icon
              >
            </span>
          </div>
          <div
            class="button-navigation"
            mwlCalendarNextView
            [view]="ViewType"
            [(viewDate)]="viewDate"
          >
            <span>
              <mat-icon
                class="mat-icon material-icons"
                role="img"
                aria-hidden="true"
                >arrow_forward_ios</mat-icon
              >
            </span>
          </div>
        </div>
        <h3>
          {{ viewDate | calendarDate: ViewType + "ViewTitle" : language() }}
        </h3>
      </div>
      <div class="right-content">
        <mat-form-field
          floatLabel="auto"
          class="calendar-selector"
          *ngIf="shouldShowCalendarSelector()"
        >
          <mat-select
            name="calendar"
            id="calendar"
            [(ngModel)]="currentCalendar"
            panelClass="calendar-select-panel"
            placeholder="{{
              'partner.campaign.contentCalendar.selectCampaign' | translate
            }}"
          >
            <ng-container *ngFor="let details of campaign.details">
              <ng-container
                *ngIf="details.calendars && details.calendars.length > 0"
              >
                <!-- Section title -->
                <mat-option class="calendar-option-language" [disabled]="true">
                  {{
                    "partner.campaign.contentCalendar.languageCampaigns"
                      | translate: { total: details.language.name }
                  }}
                </mat-option>
                <!-- Calendars -->
                <mat-option
                  class="calendar-option-calendar"
                  *ngFor="let calendar of details.calendars"
                  [value]="calendar"
                  (click)="changeCalendar(details, calendar)"
                >
                  {{
                    calendar.title
                      ? calendar.title
                      : ("partner.campaign.contentCalendar.calendarNoTitle"
                        | translate)
                  }}
                </mat-option>
              </ng-container>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <div class="button-one-click-publish-wrap">
          <button
            class="button-one-click-publish"
            (click)="startCalendarSetup()"
            [disabled]="
              !areTherePendingCalendarPosts &&
              !areTherePendingPublicationIntents
            "
            [ngClass]="{
              'button-one-click-publish--disabled':
                !areTherePendingCalendarPosts &&
                !areTherePendingPublicationIntents
            }"
            *ngIf="campaign && !campaign?.hasPartnerApprovedContentCalendar"
          >
            {{ "partner.campaign.contentCalendar.oneClickPublish" | translate }}
          </button>
          <app-guide-tooltip
            [displayNextButton]="false"
            closeButtonText="close"
            position="top-bar-schedule"
            (toolTipAction)="toolTipAction($event)"
            title="{{
              'partner.campaign.contentCalendar.tooltipStepThreeTitle'
                | translate
            }}"
            description="{{
              'partner.campaign.contentCalendar.tooltipStepThreeSubtitle'
                | translate
            }}"
            *ngIf="activeTooltipStep === 3"
            [currentStep]="3"
            [totalSteps]="3"
          />
        </div>
      </div>
    </div>
    <mwl-calendar-month-view
      [viewDate]="viewDate"
      [cellTemplate]="customCellTemplate"
      [events]="events"
      [refresh]="refresh"
      [locale]="language()"
      [weekStartsOn]="1"
      (eventTimesChanged)="eventTimesChanged($event)"
      (mouseup)="onMouseUp()"
    >
    </mwl-calendar-month-view>
    <ng-template #customCellTemplate let-day="day">
      <div class="custom-cal-day-cell" (mouseover)="mouseOverDay(day.date)">
        <div
          [ngClass]="{ 'cal-day-cell--outbound': isDayOutsideRange(day.date) }"
        >
          <div class="cal-cell-top">
            <span class="cal-day-number">
              <i
                *ngIf="isDayOutsideRange(day.date)"
                class="cal-day-outbound-icon material-icons"
                >block</i
              >
              {{ day.date | calendarDate: "monthViewDayNumber" : language() }}
            </span>
          </div>
          <div class="cal-events">
            <ng-container *ngFor="let event of getFilledEventArray(day.date)">
              <ng-container *ngIf="!isEmptyObject(event)">
                <div
                  mwlDraggable
                  class="cal-event"
                  [ngClass]="{
                    'is-first-day':
                      isMultiDayPost(event.post) && isFirstDay(day.date, event),
                    'is-center-day':
                      isMultiDayPost(event.post) &&
                      isCenterDay(day.date, event),
                    'is-last-day':
                      isMultiDayPost(event.post) && isLastDay(day.date, event),
                    'is-not-approved':
                      event.post.partnerStatus !== PostPartnerStatus.Approved,
                    'is-being-dragged': event.isBeingDragged,
                    'is-approved':
                      event.post.partnerStatus === PostPartnerStatus.Approved,
                    'calendar-accepted':
                      campaign?.hasPartnerApprovedContentCalendar,
                    'cal-event--partner': true,
                    'cal-event--partner--google': event.post.isGoogleAd,
                    'cal-event--partner--facebook': event.post.isFacebookPost,
                    'cal-event--isDarkPost': isDarkPost(event.post),
                    'cal-event--hasPromoAds':
                      event.post.hasAd || event.post.isGoogleAd,
                    loading: isEventLoading(event)
                  }"
                  [tooltipEvent]="event"
                  [tooltipTemplate]="customTooltipTemplate"
                  [mwlCalendarTooltip]="
                    event.title | calendarEventTitle: 'monthTooltip' : event
                  "
                  [tooltipAppendToBody]="true"
                  (mouseleave)="onEventLeave()"
                  (mouseenter)="onEventHover($event, event)"
                  [tooltipPlacement]="eventHovered?.tooltipPlacement || 'left'"
                  [dropData]="{ event: event }"
                  [dragAxis]="{
                    x: event.draggable && !event.loading,
                    y: event.draggable && !event.loading
                  }"
                  (dragStart)="onEventDragStart(event, day.date)"
                  (mwlClick)="onEventClick($event, event)"
                >
                  <!-- FACEBOOK POST -->
                  <ng-container
                    *ngIf="isOneDayEvent(event) || isFirstDay(day.date, event)"
                  >
                    <div
                      *ngIf="event.post.isFacebookPost && event.post.hasAd"
                      class="event-icon"
                    >
                      <img
                        *ngIf="event.post.postToFacebook"
                        src="/assets/images/facebook-post-logo.svg"
                        width="16"
                      />
                      <img
                        *ngIf="event.post.postToInstagram"
                        src="/assets/images/instagram-post-logo.svg"
                        width="16"
                      />
                    </div>
                    <div
                      *ngIf="event.post.isFacebookPost && !event.post.hasAd"
                      class="event-icon"
                    >
                      <img
                        *ngIf="showFacebookIcon(event.post)"
                        src="/assets/images/facebook-post-logo.svg"
                        width="16"
                      />
                      <img
                        *ngIf="showInstagramIcon(event.post)"
                        src="/assets/images/instagram-post-logo.svg"
                        width="16"
                      />
                    </div>
                    <div
                      *ngIf="event.post.isFacebookPost"
                      class="event-content"
                    >
                      <div class="event-text">{{ event.title }}</div>
                      <div *ngIf="event.post.hasAd" class="promo-ads-text">
                        PROMO ADS
                      </div>
                      <div class="dark-post" *ngIf="isDarkPost(event.post)">
                        <img
                          src="/assets/images/icons/ic_dark_post_Calendar.svg"
                          width="10"
                        />
                        <div class="promo-ads-text">DARK POST</div>
                      </div>
                    </div>
                    <ng-container *ngIf="event.post.isGoogleAd">
                      <div class="event-icon">
                        <img
                          src="/assets/images/google-ad-logo.svg"
                          width="16"
                        />
                      </div>
                      <div class="event-content">
                        <div class="event-text">{{ event.title }}</div>
                        <div class="promo-ads-text">PROMO ADS</div>
                      </div>
                      <mat-icon class="loading-icon">loop</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="event.post.isLinkedInPost">
                      <div class="event-icon">
                        <img
                          src="/assets/images/icons/linkedin/ic_linkedin.svg"
                          width="16"
                        />
                      </div>
                      <div class="event-content">
                        <div class="event-text">{{ event.title }}</div>
                      </div>
                      <mat-icon class="loading-icon">loop</mat-icon>
                    </ng-container>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      !isOneDayEvent(event) && !isFirstDay(day.date, event)
                    "
                  >
                    <div class="event-content"></div>
                  </ng-container>
                </div>
                <app-guide-tooltip
                  bottomIconType="ICON_TYPE_DRAG_DROP"
                  position="inside-calendar-day-right"
                  (toolTipAction)="toolTipAction($event)"
                  *ngIf="
                    activeTooltipStep === 1 &&
                    event.showTooltipType === TooltipType.DragAndDrop &&
                    (isOneDayEvent(event) || isFirstDay(day.date, event))
                  "
                  title="{{
                    'partner.campaign.contentCalendar.tooltipStepOneTitle'
                      | translate
                  }}"
                  description="{{
                    'partner.campaign.contentCalendar.tooltipStepOneSubtitle'
                      | translate
                  }}"
                  [currentStep]="1"
                  [totalSteps]="3"
                />
                <app-guide-tooltip
                  position="inside-calendar-day-right"
                  (toolTipAction)="toolTipAction($event)"
                  *ngIf="
                    activeTooltipStep === 2 &&
                    event.showTooltipType === TooltipType.TransparentPost &&
                    (isOneDayEvent(event) || isFirstDay(day.date, event))
                  "
                  title="{{
                    'partner.campaign.contentCalendar.tooltipStepTwoTitle'
                      | translate
                  }}"
                  description="{{
                    'partner.campaign.contentCalendar.tooltipStepTwoSubtitle'
                      | translate
                  }}"
                  [currentStep]="2"
                  [totalSteps]="3"
                />
                <ng-template #customTooltipTemplate let-event="event">
                  <div
                    class="cal-tooltip cal-tooltip-{{
                      eventHovered?.tooltipPlacement
                    }} ng-star-inserted"
                  >
                    <div class="cal-tooltip-inner">
                      <div class="title" *ngIf="event.post.isGoogleAd">
                        <img
                          src="/assets/images/google-ad-logo.svg"
                          width="24"
                        />
                        <h1>
                          {{
                            "partner.campaign.contentCalendar.googleAdPreview"
                              | translate
                          }}
                        </h1>
                      </div>
                      <app-generic-post-mock-up
                        [post]="event.post"
                        [partner]="partner"
                      />
                    </div>
                  </div>
                </ng-template>
              </ng-container>
              <ng-container *ngIf="isEmptyObject(event)">
                <div class="empty-event"></div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
